<template>
  <div class="world_box main">
    <div class="banner_box">
      <div class="swiper_box">
        <img src="@/assets/images/Community of Innovation.jpg" />
      </div>
    </div>

    <div class="container content" v-if="cat == 1">
      <div class="title_">ECI @ Meta</div>
      <div class="text_">
        We are embracing metaverse. ECI will have awards for metaverse
        innovation this year.<br />
        Our award is called ECI@meta, It will be the first metaverse award.
      </div>
    </div>
    <div class="container content" v-else>
      <div class="title_">Artech @ ECI</div>
      <div class="text_">
        We are working on building a community for artists from all around the
        world. <br />
        ECI will support them through Art Exhibition, Art Residence, Art
        heeling, NFT Creation Camp, <br />
        etc and help them to finance, raising funds to create NFT projects.
      </div>
    </div>
    <div class="tips3">
      <div class="container content">
        <div class="title_">
          <!-- <span>Join Us！</span><br /> -->
          Please fill in the form below
        </div>
        <div class="part_form">
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
            <el-form-item
              label="First Name:"
              prop="first_name"
              label-width="150px"
            >
              <el-input v-model="ruleForm.first_name"></el-input>
            </el-form-item>
            <el-form-item
              label="Last Name:"
              prop="last_name"
              label-width="150px"
            >
              <el-input v-model="ruleForm.last_name"></el-input>
            </el-form-item>

            <el-form-item
              label="Company:"
              prop="company_name"
              label-width="150px"
            >
              <el-input v-model="ruleForm.company_name"></el-input>
            </el-form-item>
            <el-form-item
              label="Job Title:"
              prop="job_title"
              label-width="150px"
            >
              <el-input v-model="ruleForm.job_title"></el-input>
            </el-form-item>
            <el-form-item
              label="Mobile:"
              prop="phone_number"
              label-width="150px"
            >
              <el-input v-model="ruleForm.phone_number"></el-input>
            </el-form-item>
            <el-form-item label="Email:" prop="email" label-width="150px">
              <el-input v-model="ruleForm.email"></el-input>
            </el-form-item>
            <el-form-item label="Country:" prop="country" label-width="150px">
              <el-input v-model="ruleForm.country"></el-input>
            </el-form-item>

            <el-form-item
              label="Your Message:"
              prop="message"
              class="textarea"
              label-width="150px"
            >
              <el-input type="textarea" v-model="ruleForm.message"></el-input>
            </el-form-item>
            <div class="button" @click="submitForm('ruleForm')">SUBMIT</div>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { JOIN_US } from '@/api/index.js';
export default {
  name: 'World',
  components: {},
  data() {
    return {
      cat: this.$route.query.cat || '1',

      ruleForm: {
        first_name: '',
        last_name: '',
        email: '',
        company_name: '',
        job_title: '',
        country: '',
        phone_number: '',
        message: '',
      },
      rules: {
        first_name: [
          { required: true, message: 'Please enter', trigger: 'blur' },
        ],
        last_name: [
          { required: true, message: 'Please enter', trigger: 'blur' },
        ],
        job_title: [
          { required: true, message: 'Please enter', trigger: 'blur' },
        ],

        email: [{ required: true, message: 'Please enter', trigger: 'blur' }],
        phone_number: [
          { required: true, message: 'Please enter', trigger: 'blur' },
        ],
        company_name: [
          { required: true, message: 'Please enter', trigger: 'blur' },
        ],
        country: [{ required: true, message: 'Please enter', trigger: 'blur' }],
        message: [{ required: true, message: 'Please enter', trigger: 'blur' }],
      },
    };
  },
  created() {},
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (this.$route.query.cat == 1) {
            this.ruleForm.type = 'meta';
          } else {
            this.ruleForm.type = 'artech';
          }
          JOIN_US(this.ruleForm).then(res => {
            console.log(res);
            if (res.errcode == 0) {
              this.$message({
                type: 'success',
                message: 'success',
              });
              this.ruleForm = {
                first_name: '',
                last_name: '',
                email: '',
                company_name: '',
                job_title: '',
                country: '',
                phone_number: '',
                message: '',
              };
            } else {
              this.$message({
                type: 'warning',
                message: res.errmsg,
              });
            }
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.world_box {
  .title_ {
    text-align: center;
    margin-bottom: 20px;
  }
  .text_ {
    text-align: center;
    padding-bottom: 50px;
  }

  .tips3 {
    .title_ {
      span {
        color: $blue;
      }
    }
    .part_form {
      width: 480px;
      margin: 0 auto;
    }
  }
}
@media (max-width: 1000px) {
  .world_box {
    .tips3 {
      .part_form {
        width: 100%;
        margin: 0 auto;
      }
    }
  }
}
</style>
